import {
  patchReviewV2,
  useGetAverageReviews,
  useGetReviewOne,
  usePostReviewImgs
} from '#api/reviews';
import { Button, Text, Textarea } from '#atoms';
import { REVIEW_LIST } from '#const/review';
import { ImageWithUpload, TitleItemBox } from '#molecules';
import { useToast } from '#providers/ToastProvider';
import { HeaderBarTemplate } from '#templates';
import { imageFileType } from '#types/fileType';
import { createImgToFormData } from '#utils/createImgToFormData';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useUploadImages } from 'src/components/molecules/ImageWithUpload/hooks';
import { RatingStars } from 'src/components/molecules/RatingStar';
import {
  BottomArea,
  Container,
  InfoWrapper,
  PageContainer,
  ReviewCategory,
  ReviewListWrapper,
  StarArea,
  TextArea
} from 'src/components/pages/FinalRating/styles';

interface RatingProps {}

const RatingPage = ({}: RatingProps) => {
  const { registeredLectureId, lectureId } = useParams();

  const { data } = useGetReviewOne(registeredLectureId || '');

  const { data: averageReviews } = useGetAverageReviews(lectureId ?? '');

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { showToast } = useToast();

  const { mutateAsync: postReviewImgsMutate } = usePostReviewImgs();

  const { mutate } = useMutation(patchReviewV2, {
    onSuccess: (res: string) => {
      queryClient.invalidateQueries(['REVIEWS']);
      if (localImgs.length > 0) {
        postReviewImgsMutate(
          {
            rateId: res,
            payload: createImgToFormData(localImgs)
          },
          {
            onError: () => {
              showToast({
                type: 'fail',
                message: '사진 등록에 실패했습니다.'
              });
            },
            onSuccess: () => {
              showToast({
                type: 'success',
                message: '리뷰 등록에 성공했습니다.'
              });
              navigate(-1);
            }
          }
        );
      } else {
        showToast({
          type: 'success',
          message: '리뷰 등록에 성공했습니다.'
        });
        navigate(-1);
      }
    }
  });

  const [reviewText, setReviewText] = useState<string>('');

  const handleSubmit = () => {
    mutate({
      registeredLectureId: registeredLectureId || '',
      desc: reviewText
    });
  };

  const { localImgs, handleUploadImages, handleDeleteLocalImage } =
    useUploadImages({
      fileType: 'image'
    });

  if (!data || !averageReviews) return null;

  return (
    <PageContainer>
      <HeaderBarTemplate TitleCenter={<Text textStyle="t3">리뷰 등록</Text>}>
        <Container>
          <TextArea>
            <Text children="리뷰를 등록해주세요" textStyle="t1" />
            <div>
              {/* <Text textColor="gray2" textStyle="t3">
                {dottedDateFormatter(data.startAt)} ~{' '}
                {dottedDateFormatter(data.endAt)}
              </Text> */}
              <InfoWrapper>
                <Text textColor="gray2" textStyle="t3">
                  {averageReviews.lecture.title}
                </Text>
                <Text textColor="gray2" textStyle="t3">
                  {averageReviews.lecture.coachName} 전문가
                </Text>
              </InfoWrapper>
            </div>
          </TextArea>
          <BottomArea>
            <ReviewListWrapper>
              {REVIEW_LIST.map(({ id, title }) => (
                <ReviewCategory key={id}>
                  <Text textStyle="bb">{title}</Text>
                  <StarArea>
                    <RatingStars mode="register" rate={data[id]} />
                  </StarArea>
                </ReviewCategory>
              ))}
            </ReviewListWrapper>
            <Textarea
              height={19}
              limit={500}
              value={reviewText}
              onChange={setReviewText}
              placeholder="수업 리뷰를 적어주세요"
            />
            <TitleItemBox title="사진 첨부" type="images" isOptional>
              <ImageWithUpload
                localImages={localImgs}
                onUploadImage={handleUploadImages}
                onDeleteLocalImage={handleDeleteLocalImage}
                fileType={imageFileType}
              />
            </TitleItemBox>
            <Button
              children="리뷰 등록하기"
              onClick={handleSubmit}
              disabled={!reviewText}
            />
          </BottomArea>
        </Container>
      </HeaderBarTemplate>
    </PageContainer>
  );
};

export default RatingPage;
