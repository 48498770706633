import { IGetAllCoachAccountsProps } from '#types/api';
import { IGetClinicalDataProps } from '#types/clinicalData';
import { IGetCoachRegLecturesProps } from './registered-lectures';

export const queryKeyFactory = {
  GET_REVIEW: (registeredLectureId: string) => [
    'REVIEW',
    `${registeredLectureId}`
  ],
  GET_REVIEW_ONE: (registeredLectureId: string) => [
    'REVIEWONE',
    `${registeredLectureId}`
  ],
  GET_TEMP: () => ['TEMP'],
  GET_MY_ACCOUNT: () => ['MYACCOUNT'],
  GET_LECTURE: (lectureId: string) => ['LECTURES', `${lectureId}`],
  GET_COACH_LECTURES: (coachId: string) => ['LECTURES', `${coachId}`],
  GET_LECTURES_ING_LIST: () => ['LECTURESINGLIST'],
  GET_LECTURES_ING: (lectureId: string) => ['LECTURESING', `${lectureId}`],
  GET_PHONE_AUTH: () => ['PHONEAUTH'],
  GET_ACCOUNT: () => ['ACCOUNT'],
  GET_NICKNAME_DUPLICATE_CHECK: (nickname: string) => [
    'NICKNAMEDUPLICATECHECK',
    `${nickname}`
  ],
  GET_CONFIRMATION_FORMS: () => ['CONFIRMATIONFORMS'],
  GET_RECORDS: (registeredLectureId: string) => [
    'RECORDS',
    `${registeredLectureId}`
  ],
  GET_ALL_COACH_ACCOUNTS: ({
    page,
    offset,
    tag,
    grade,
    reviewCount,
    distance,
    minPrice,
    maxPrice,
    lat,
    lng,
    districtCode
  }: IGetAllCoachAccountsProps) => [
    'ALLCOACHACCOUNTS',
    `${page}`,
    `${offset}`,
    `${tag}`,
    `${grade}`,
    `${distance}`,
    `${reviewCount}`,
    `${minPrice}`,
    `${maxPrice}`,
    `${lat}`,
    `${lng}`,
    `${districtCode}`
  ],
  GET_COACH_SCHEDULES: (coachId: string, startAt: string, gymId?: string) => [
    'COACHSCHEDULES',
    `${coachId}`,
    `${gymId}`,
    `${startAt}`
  ],
  GET_GYM: (gymId: string) => ['GYM', `${gymId}`],
  GET_PAIN_PART_KINDS: () => ['PAINPARTKINDS'],
  GET_TAGS: () => ['TAGS'],
  GET_ALL_TAGS: () => ['ALLTAGS'],
  GET_DISEASE_KINDS: () => ['DISEASEKINDS'],
  GET_REGISTERED_LECTURES: () => ['REGISTEREDLECTURES'],
  GET_HEALTH_INFOS: () => ['HEALTHINFOS'],
  GET_NEAR_GYMS: (
    page: number,
    offset: number,
    maxDistance: number,
    lat: number,
    lng: number
  ) => [
    'NEARGYMS',
    `${page}`,
    `${offset}`,
    `${maxDistance}`,
    `${lat}`,
    `${lng}`
  ],
  GET_COORDINATES: (query: string) => ['COORDINATES', `${query}`],
  GET_ADDRESS: (longitude: number, latitude: number) => [
    'COORDINATES',
    `${longitude}`,
    `${latitude}`
  ],
  GET_BANKS: (page?: number, offset?: number, name?: string) => [
    'BANKS',
    `${page}`,
    `${offset}`,
    `${name}`
  ],
  GET_SETTLEMENTS: (
    findStartDate: string,
    findEndDate: string,
    paymentType?: string,
    sort?: string
  ) => [
    'SETTLEMENTS',
    `${findStartDate}`,
    `${findEndDate}`,
    `${paymentType}`,
    `${sort}`
  ],
  GET_REVIEWS: (coachId: string, order?: number) => [
    'REVIEWS',
    `${order}`,
    `${coachId}`
  ],
  GET_COACH_AVERAGE_RATES: (coachId: string) => ['AVERAGERATES', `${coachId}`],
  GET_COACH_SCHEDULE: ({
    coachId,
    gymId,
    startAt
  }: {
    coachId: string;
    gymId?: string;
    startAt: string;
  }) => ['COACHSCHEDULES', `${coachId}`, `${gymId}`, `${startAt}`],
  GET_JOBS: () => ['JOBS'],
  GET_ACCOUNT_MYPAGE: () => ['ACCOUNTMYPAGE'],
  GET_COACH_REGISTERED_LECTURES: ({
    page,
    offset,
    startAt
  }: IGetCoachRegLecturesProps) => [
    'COACHREGISTEREDLECTURES',
    `${page}`,
    `${offset}`,
    `${startAt}`
  ],
  GET_ALL_COACH_ACCOUNTS_PUBLIC: ({
    page,
    offset,
    tag,
    grade,
    reviewCount,
    distance,
    minPrice,
    maxPrice,
    lat,
    lng,
    districtCode
  }: IGetAllCoachAccountsProps) => [
    'ALLCOACHACCOUNTSPUBLIC',
    `${page}`,
    `${offset}`,
    `${tag}`,
    `${grade}`,
    `${distance}`,
    `${reviewCount}`,
    `${minPrice}`,
    `${maxPrice}`,
    `${lat}`,
    `${lng}`,
    `${districtCode}`
  ],
  GET_CLINICAL_DATA_LIST: ({
    coachId,
    page,
    offset
  }: IGetClinicalDataProps) => [
    'CLINICALDATALIST',
    `${coachId}`,
    `${page}`,
    `${offset}`
  ],
  GET_CLINICAL_DATA: (id: string) => ['CLINICALDATA', `${id}`],
  GET_COACH_ALL_RECORDS: ({
    page,
    offset
  }: {
    page?: number;
    offset?: number;
  }) => ['COACHALLRECORDS', `${page}`, `${offset}`],
  GET_EXERCISE_TYPES: () => ['EXERCISETYPES'],
  GET_REGISTERED_LECTURE: (registeredLectureId: string) => [
    'REGISTEREDLECTURE',
    `${registeredLectureId}`
  ],
  GET_DISTRICTS: ({ page, offset }: { page?: number; offset?: number }) => [
    'DISTRICTS',
    `${page}`,
    `${offset}`
  ],
  GET_AVERAGE_REVIEWS: (coachId: string) => ['AVERAGEREVIEWS', `${coachId}`],
  GET_RECENT_REVIEWS: () => ['RECENTREVIEWS'],
  GET_RECENT_CLINICAL_DATA: () => ['RECENTCLINICALDATA'],
  GET_MYURL_FROM_COACHID: (coachId: string) => [
    'MYURLFROMCOACHID',
    `${coachId}`
  ],
  GET_COACHID_FROM_MYURL: (myUrl: string) => ['COACHIDFROMMYURL', `${myUrl}`],
  GET_SCHEDULE_PER_WEEK: (startAt: string, duration: number) => [
    'SCHEDULEPERWEEK',
    startAt,
    `${duration}`
  ],
  GET_USERNAME: (name?: string) => ['USERNAME', name],
  GET_LECTURES_SEARCH: (coachId: string, title?: string) => [
    'COACHID',
    `${coachId}`,
    'TITLE',
    `${title}`
  ],
  GET_MEMBER_LATEST_LECTURE: (personId: string, lectureId?: string) => [
    'MEMBER_LATEST_LECTURE',
    `${personId}`,
    `${lectureId}`
  ]
};
