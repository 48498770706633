import { BoxIcon, Button, Spacing, Text } from '#atoms';
import { PATH } from '#const/path';
import { ClassEvaluationModal } from '#molecules';
import UserInfo, { useUserInfo } from '#providers/UserInfo';
import { RegisteredLectureInfo } from '#types/registeredLectures';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTodayLecture } from './hooks';
import {
  Container,
  ContentArea,
  ContentBox,
  DrawerHandler,
  TextContainer,
  TodayLecture,
  ToggleButton
} from './styles';

const currentLectureStatus = {
  UPCOMING: '수업 예정',
  ONGOING: '진행중',
  COMPLETED: '수업 완료'
} as const;

const timeToStringTime = (time: number) => {
  return `${Math.floor(time / 60)}:${(time % 60).toString().padStart(2, '0')}`;
};

export const MainBottomSheet = () => {
  const [showRatingModal, setShowRatingModal] = useState<boolean>(false);
  const [selectedRegisteredLecture, setSelectedRegisteredLecture] =
    useState<RegisteredLectureInfo | null>(null);

  const [isExpand, setIsExpand] = useState(false);
  const navigate = useNavigate();
  const { userInfo, registeredLectureCounts, registeredLectureInfo } =
    useUserInfo();
  const { todayLecture } = useTodayLecture();

  const handleContainerClick = () => {
    if (userInfo && !isExpand) return handleToggleButtonClick();
    if (!userInfo) return navigate(PATH.SIGN);
  };

  const handleToggleButtonClick = () => {
    setIsExpand(prev => !prev);
  };

  const onButtonClick = (registeredLecture: RegisteredLectureInfo) => {
    if (registeredLecture.status === 'UPCOMING') {
      navigate(
        `${PATH.MYPAGE_SCHEDULES}/${registeredLecture.lectureId}/cancellation?id=${registeredLecture.registeredLectureId}`
      );
      return;
    }
    setShowRatingModal(true);
    setSelectedRegisteredLecture(registeredLecture);
  };

  return (
    <Container
      className={classNames({
        fold: !isExpand,
        login: userInfo,
        consumer: userInfo && !userInfo.isCoach,
        trainer: userInfo && userInfo.isCoach,
        none: userInfo && !userInfo.isCoach && !registeredLectureCounts
      })}
      onClick={handleContainerClick}
    >
      <UserInfo.Logout>
        <ToggleButton
          className={classNames({ fold: !isExpand })}
          onClick={() => isExpand && handleToggleButtonClick()}
        >
          {/* <BoxIcon type="down-arrow" onlyIcon size={24} /> */}
        </ToggleButton>
        <ContentArea className={classNames({ fold: !isExpand })}>
          <Text textStyle="bb" textColor="black">
            피벗 로그인
          </Text>
          {isExpand && (
            <>
              <Spacing size={14} />
              <Button onClick={() => navigate(PATH.SIGN)}>로그인하기</Button>
            </>
          )}
        </ContentArea>
      </UserInfo.Logout>
      <UserInfo.IsConsumer>
        {registeredLectureCounts && (
          <>
            <ToggleButton
              className={classNames({ fold: !isExpand })}
              onClick={() => isExpand && handleToggleButtonClick()}
            >
              <BoxIcon type="down-arrow" onlyIcon size={24} />
            </ToggleButton>
            <ContentArea className="consumer">
              {isExpand ? (
                <>
                  {registeredLectureInfo.map((registeredLecture, idx) => (
                    <ContentBox key={idx}>
                      <div data-text-type="t3" className="flex">
                        <Text textColor="point">
                          {registeredLecture.currentCount}회차{' '}
                          {currentLectureStatus[registeredLecture.status]}
                        </Text>
                        <Text textColor="black">
                          {registeredLecture.coachName} 전문가
                        </Text>
                      </div>
                      <div>
                        <div className="flex">
                          <Text textColor="dark">
                            {registeredLecture.lectureTitle}
                          </Text>
                          <Text textColor="dark">
                            {registeredLecture.location}
                          </Text>
                        </div>
                        <Text textColor="dark">
                          {registeredLecture.taughtAt}
                        </Text>
                      </div>
                      <Spacing size={16} />
                      <Button
                        background={
                          registeredLecture.status === 'UPCOMING'
                            ? 'anti'
                            : undefined
                        }
                        onClick={() => onButtonClick(registeredLecture)}
                      >
                        {registeredLecture.status === 'UPCOMING'
                          ? '예약 취소'
                          : registeredLecture.isFinal
                          ? '마지막 리뷰 남기기'
                          : '리뷰 남기기'}
                      </Button>
                    </ContentBox>
                  ))}
                </>
              ) : (
                <TextContainer>
                  {registeredLectureCounts.reservationCount > 0 && (
                    <Text>
                      예약된 수업 {registeredLectureCounts.reservationCount}개
                    </Text>
                  )}
                  {registeredLectureCounts.unreviewedCount > 0 && (
                    <Text textStyle="c1" textColor="gray">
                      {registeredLectureCounts.unreviewedCount}
                      개의 리뷰를 작성해주세요
                    </Text>
                  )}
                </TextContainer>
              )}
            </ContentArea>
          </>
        )}
      </UserInfo.IsConsumer>
      <UserInfo.IsCoach>
        <DrawerHandler className={classNames({ hide: !todayLecture.length })} />
        <ContentArea className={classNames({ fold: !isExpand }, 'trainer')}>
          {todayLecture.length ? (
            <>
              <Text textStyle="t3" textColor="black">
                오늘은 수업이 예정되어 있어요!
              </Text>
              {todayLecture.map(
                ({ tranieeName, place, startTime, endTime }, idx) => (
                  <TodayLecture key={idx}>
                    <Text textColor="gray2">{tranieeName} 고객님</Text>
                    <div>
                      <Text textStyle="t3" textColor="black">
                        {place}
                      </Text>
                      <Text>{`${timeToStringTime(
                        startTime
                      )} - ${timeToStringTime(endTime)}`}</Text>
                    </div>
                  </TodayLecture>
                )
              )}
            </>
          ) : (
            <Text textStyle="t3" textColor="black">
              오늘 예정된 수업이 없습니다
            </Text>
          )}
        </ContentArea>
      </UserInfo.IsCoach>
      {showRatingModal && selectedRegisteredLecture && (
        <ClassEvaluationModal
          registeredLectureId={selectedRegisteredLecture.registeredLectureId}
          round={selectedRegisteredLecture.currentCount}
          isFinal={selectedRegisteredLecture.isFinal}
          lectureId={selectedRegisteredLecture.lectureId}
          onClose={() => setShowRatingModal(false)}
        />
      )}
    </Container>
  );
};
